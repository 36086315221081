import logo from "./logo.svg";
import "./App.css";

function myClick() {
  return alert("Hello PayProp Developers!");
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <span>
          <strong style={{ color: "yellow" }}>API Base Url:</strong>{" "}
          {window.env.API_ROOT}
        </span>
        <span>
          <strong style={{ color: "yellow" }}>Environment:</strong>{" "}
          {window.env.ENVIRONMENT}
        </span>
        <span>
          <strong style={{ color: "yellow" }}>Currency:</strong>{" "}
          {window.env.CURRENCY}
        </span>
        <button style={{ marginTop: "2em" }} type="button" onClick={myClick}>
          Random Button
        </button>
      </header>
    </div>
  );
}

export default App;
